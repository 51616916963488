<template>
  <div class="contractdrag">
    <el-image class="img" :src="require('@/assets/img/home/contractbg.png')" />
    <el-upload ref="uploadRef" class="upload-demo" drag action="#" :accept="file_accept"
      :http-request="httpRequest" multiple>
      <div class="middle" v-show="!state.nowFile">
        <div class="mb-20">拖动合同至此进行验签</div>
        <oabutton title="合同验签" CSStype=8 width=158 height=52 style="border-radius: 8px;"></oabutton>
      </div>
    </el-upload>
  </div>
  <confirm ref="confirmRef"></confirm>
</template>
<script setup>
import { reactive, ref, unref, nextTick } from "vue";
import { handleMessage } from "@/utils/server/confirm";
import { file_accept } from "@/utils/base/accept.js";
import { getfileTypeName } from "@/utils/server/file.js"
import {verifySignatures} from "@/utils/server/upload"
import confirm from "./contractdrag/confirm.vue"
const confirmRef=ref()
const state = reactive({
  nowFile: false,
});
// 文件上传
const httpRequest = (rawFile) => {
  handleMessage('开始合同验签','success')
  let fileTypeName = getfileTypeName(rawFile.file.name)
  let filetype = ['pdf', 'PDF', 'docx', 'doc', 'DOCX', 'DOC', 'xls', 'xlsx', 'XLS', 'XLSX', 'word', 'WORD', 'txt', 'TXT']
  if (rawFile.file.size / 1024 / 1024 > 500) {
    handleMessage('文档大小超过500MB')
  } else if (filetype.includes(fileTypeName)) {
    verifySignatures(rawFile.file).then(res=>{
      // signAllDoc 签名是否覆盖全文
      // signAuth 签名是否有效
      //  signName 签名名称
      unref(confirmRef).getData(res.data)
      // console.log('合同验签res',res)
    })
  } else {
    handleMessage('请上传正确文档格式格式!')
  }
}
</script>

<style lang="scss" scoped>
.contractdrag {
  position: relative;
  width: 318px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  background: linear-gradient(180deg, #EEEEEE 0%, rgba(40, 144, 255, 0.14) 0%, #fff 100%);

  ::v-deep .upload-demo {
    width: 273px;
  }

  ::v-deep .el-upload-dragger {
    background-color: transparent;
  }

  ::v-deep .el-upload-list {
    display: none;
  }

  .img {
    width: 140px;
    height: 153px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
}</style>
